import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class RecaptchaService {

    constructor(private http: HttpClient) { }

    obtenerRecaptchas() {
        return this.http.get<any>(`${environment.url_api}/recaptchas`)
            .pipe(map((res) => {
                return res;
            }));
    }

}
