import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SidenavService {
  private sidenavOpenedSubject = new BehaviorSubject<boolean>(true);

  private mobileQuerySubject = new BehaviorSubject<boolean>(true);

  constructor() {
  }

  set sidenavOpened(value: boolean) {
    this.sidenavOpenedSubject.next(value);
  }

  get sidenavOpenedObs(): Observable<boolean> {
    return this.sidenavOpenedSubject.asObservable();
  }

  set mobileQuery(value: boolean) {
    this.mobileQuerySubject.next(value);
  }

  get mobileQueryObs(): Observable<boolean> {
    return this.mobileQuerySubject.asObservable();
  }

}
