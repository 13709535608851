import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subscription, Observable } from "rxjs";

import { map, pluck } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class GofeelsPayService {

    constructor(private http: HttpClient) { }

    /** Ordenes */

    getOrdenes(page: number) {
        return this.http.get<any>(`${environment.url_api}/dashboard/payment-gateway-transactions`, {
          params: {
            page,
            order_by: 'id.desc'
          }
        })
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    /* MOTOR DE RESERVA */

    /**
     * Obtencion de pagos realizados desde el motor de reserva
     */
    getPagosGofeelsPayMotor(filtro: any, page: number, pageSize: number, orderby: string, propiedadId: number) {
        let filtro_url = null;
        filtro === null ?
            filtro_url = `?page=${page}&por_pagina=${pageSize}&orderby=${orderby}` :
            filtro_url = `?${filtro}&page=${page}&por_pagina=${pageSize}&orderby=${orderby}`;

        if (propiedadId) {
            filtro_url = `${filtro_url}&propiedad_id=${propiedadId}`;
        }

        return this.http.get<any>(`${environment.url_api}/dashboard/gofeelspay/motor_reservas/pagos${filtro_url}`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

  /**
   * Obtencion de pagos vínculados a un link de cobro
   */
  getPagosGofeelsPayLinkCobro(filtro: any, page: number, pageSize: number, orderby: string, propiedadId: number) {
    let filtro_url = null;
    filtro === null ?
      filtro_url = `?page=${page}&por_pagina=${pageSize}&orderby=${orderby}` :
      filtro_url = `?${filtro}&page=${page}&por_pagina=${pageSize}&orderby=${orderby}`;

    if (propiedadId) {
      filtro_url = `${filtro_url}&propiedad_id=${propiedadId}`;
    }

    return this.http.get<any>(`${environment.url_api}/dashboard/gofeelspay/link_cobro/pagos${filtro_url}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

    /**
     * Obtencion de comisiones desde el motor de reserva
     */
    getTotalComisionesGofeelsPayMotor(fechaInicio: string, fechaFin: string, propiedadId: number) {
        let filtro_url = `?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`;

        if (propiedadId) {
            filtro_url = `${filtro_url}&propiedad_id=${propiedadId}`;
        }

        return this.http.get<any>(`${environment.url_api}/dashboard/gofeelspay/motor_reservas/total_comisiones${filtro_url}`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    /**
     * Obtencion de listado de propiedad que tuvieron pagos
     */
    getPropiedadesConPago(fechaInicio: string, fechaFin: string) {
        return this.http.get<any>(`${environment.url_api}/dashboard/gofeelspay/motor_reservas/propiedades-con-pagos?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}&orderby=nombre.asc`)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

    /**
     * Obtencion de pagos realizados desde el motor de reserva
     * @param data { pagos: [ids] }
     */
    confirmarPagosGofeelsPayMotor(data: any) {
        return this.http.post<any>(`${environment.url_api}/dashboard/gofeelspay/confirmar_pagos`, data)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

  confirmarPagosGofeelsPayLinkCobro(data: any) {
    return this.http.post<any>(`${environment.url_api}/dashboard/gofeelspay/confirmar_pagos_link_cobro`, data)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

    /**
     * Obtencion de pagos realizados desde el motor de reserva
     */
    getPagosGofeelsPayModulos() {
        return this.http.get<any>(`${environment.url_api}/dashboard/pagos/modulos`)
            .pipe(
                map((res) => {
                    return res.pagos;
                })
            );
    }


    ingresarPagoGofeelsPayMotorReserva(data: any) {
        return this.http.post<any>(`${environment.url_api}/dashboard/ingresar_pago_webpay`, data)
            .pipe(
                map((res) => {
                    return res;
                })
            );
    }

}
