import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';

import { map, pluck } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

declare var require: any;
const FileSaver = require('file-saver');

@Injectable()
export class FilesService {
  option: any;

  constructor(private http: HttpClient) {
    this.option = {
      responseType: 'blob',
    };
  }

  generateExcelPropiedades(filtro: string) {
    this.http
      .get<any>(
        `${environment.url_api}/dashboard/propiedades/exportar/excel?${filtro}`,
        this.option
      )
      .subscribe(
        (response) => {
          // download file
          FileSaver.saveAs(response, `propiedades`);
        },
        (error) => {
          console.error(`Error: ${error.message}`);
        }
      );
  }

  generateExcelPagosGofeelsPayMotor(data: any) {
    this.http
      .post<any>(
        `${environment.url_api}/dashboard/gofeelspay/motor_reservas/pagos/exportar/excel`,
        data,
        this.option
      )
      .subscribe(
        (response) => {
          // download file
          FileSaver.saveAs(response, `pagosGofeelsPay`);
        },
        (error) => {
          console.error(`Error: ${error.message}`);
        }
      );
  }

  generateExcelPagosGofeelsPayLinkCobro(data: any) {
    this.http
      .post<any>(
        `${environment.url_api}/dashboard/gofeelspay/link_cobro/pagos/exportar/excel`,
        data,
        this.option
      )
      .subscribe(
        (response) => {
          // download file
          FileSaver.saveAs(response, `pagosGofeelsPay`);
        },
        (error) => {
          console.error(`Error: ${error.message}`);
        }
      );
  }

  generateExcelPaidSubscriptions() {
    this.http
      .post<any>(
        `${environment.url_api}/dashboard/gofeelspay/payment-gateway-transactions/excel`,
        [],
        this.option
      )
      .subscribe(
        (response) => {
          // download file
          FileSaver.saveAs(response, `PaidSubscriptions`);
        },
        (error) => {
          console.error(`Error: ${error.message}`);
        }
      );
  }
}
