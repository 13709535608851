import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import * as moment from 'moment';


@Injectable()
export class FiltrosService {
	private filtrosPropiedadesSubject = new BehaviorSubject<any[]>([{
        id: 1,
        value: 'Fecha de creación',
        params: {
          fecha_inicio: moment().subtract(14, 'days').format('YYYY-MM-DD'),
          fecha_fin: moment().format('YYYY-MM-DD')
        },
        url: `fecha_inicio=${moment().subtract(14, 'days').format('YYYY-MM-DD')}&fecha_fin=${moment().format('YYYY-MM-DD')}`
      }]);

	constructor(private http: HttpClient) { }

	set filtrosPropiedades(value: any[]) {
		this.filtrosPropiedadesSubject.next(value);
	}

	get filtrosPropiedades(): any[] {
		return this.filtrosPropiedadesSubject.value;
	}

	
}
