import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import * as Guards from './modules/core/guards';

const routes: Routes = [
  {
    path: "login",
    canActivate: [Guards.NotLoggedInGuard],
    loadChildren: () =>
      import("./modules/login/login.module").then(
        (m) => m.LoginModule
      ),
  },
  {
    path: "",
    canActivate: [Guards.AuthGuard],
    loadChildren: () =>
      import("./modules/main/main.module").then(
        (m) => m.MainModule),
  },
  { path: "**", pathMatch: "full", redirectTo: "login" },
  // { path: "**", component: PathNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
