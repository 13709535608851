import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subscription, Observable } from "rxjs";

import { first, map, pluck } from "rxjs/operators";

import { environment } from "../../../../environments/environment";

import { Usuario } from "class/*";

@Injectable()
export class UsuarioService {
	private usuarioSubject = new BehaviorSubject<Usuario>(null);

	constructor(private http: HttpClient) { }

	set usuario(value: Usuario) {
		this.usuarioSubject.next(value);
	}

	/**
	 * Cliente logueado actualmente.
	 */
	get usuarioObs(): Observable<Usuario> {
		return this.usuarioSubject.asObservable();
	}

	//   actualizarUsuario(usuario: any) {
	//     return this.http.put<any>(`${environment.url_api}/usuario`, usuario)
	//       .pipe(map((res) => res.data.usuario));
	//   }

	/**
	 * Obtiene toda la información del usuario actualmente logueado.
	 */
	getUsuario(token: string) {
		return this.http.get<any>(`${environment.url_api}/dashboard/usuarios/${token}`).pipe(
			first(),
			map((res) => {
				return res.body;
			})
		);
	}

	/**
	 * Obtiene todos los usuarios de la propiedad seleccionada
	 */
	getUsuariosPropiedadById(propiedad_id: number) {
		return this.http.get<any>(`${environment.url_api}/dashboard/propiedades/${propiedad_id}/usuarios`).pipe(
			map((res) => {
				return res.body;
			})
		);
	}

	/**
	 * Cambio de propiedad del usuario
	 * @param usuarioId Id del usuario a editar
	 * @param propiedadId Id de la propiedad para asignar al usuario
	 */
	actualizarUsuarioPropiedad(usuarioId: number, propiedadId: number) {
		return this.http.put<any>(`${environment.url_api}/dashboard/usuarios/${usuarioId}/actualizar_propiedad`,
			{ propiedad_id: propiedadId })
			.pipe(
				first()
				// map((res: any) => res)
			);
	}

	/**
	 * Reset de la pass del usuario
	 * @param usuario_id 
	 */
	resetPassUsuario(usuario_id: number) {
		return this.http.post<any>(`${environment.url_api}/dashboard/usuario/${usuario_id}/reset/password`, {})
			.pipe(map((res) => res));
	}

	enviarCorreoVerificacion(usuario_id: number) {
		return this.http.post<any>(`${environment.url_api}/dashboard/usuario/${usuario_id}/reenviar/verificacion`, {})
			.pipe(map((res) => res));
	}

	
	editarPasoUsuario(usuarioId: number, paso: number) {
		return this.http.put<any>(`${environment.url_api}/user/${usuarioId}`, {
			paso: paso
		})
			.pipe(map((res) => res));
	}

}
